import React from 'react'

import {Footer, SignupDivider, TextPage} from '../../components';
import {WebLayout} from '../../layouts';

const Privacy = () => (
  <WebLayout>
    <TextPage title="Privacy Policy">
      <h3>Duuers - Privacy Policy</h3>
       <h6>Recital</h6>
       <p>
      By this privacy policy, Duuers Oy (hereinafter “we”, “us”, “our”) want to inform you of what kind of data we may collect and how we collect, process and use your information in connection with providing a website-based service for preparing and managing business proposals and contracts (“Service”). We operate as a responsible controller for your personal data as regulated in General Data Protection Regulation (GDPR) and applicable national legislation. Our contact information can be found at the end of this document.
      </p>
      <p>
      Please note that this privacy policy is limited to data collected by us related to your use of the Service. We are not responsible for the actions of other parties, the content of their websites or services, the use of data you provide to them or any products or services they may offer.
      </p>
      <p>
      Please note that by creating an account for the Service or using it, your personal data will be processed according to the principles set forth in this privacy policy. If you do not accept the processing of personal data in accordance with this Privacy Policy, do not register with the Service and do not log in to or use the Service.
      </p>
      <h6>The Purpose and Legal Basis for Processing Data</h6>
      <p>
      The basis for processing personal data is your consent (asked for at the time of creating an account for use in the Service). You may withdraw your consent to process your personal data at any time. Your personal data may also be processed on the basis of our legitimate interest (e.g. to establish a customer database), in which case we may retain some of your personal data even after you have withdrawn your consent or stopped using the Service, if there exists a justifiable reason for us to do so (we may need to retain some details for taxation or marketing purposes, for example). Please note that if you withdraw your content and we do not have a legitimate interest to continue processing your data, you may not be able to use the Service any longer.
      </p>
      <p>
      The purpose for processing the data is to provide the Service, through which we offer you the platform for generating, preparing and managing business proposals and contracts in co-operation with your customers. Another purpose of the processing is to keep a customer database for billing and customer care purposes. Some of the personal data may also be processed for the purpose of displaying and sending advertisement and marketing messages.
      </p>
      <h6>What Data Do We Collect and Why, and From Which Regular Sources?</h6>
      <p>
      In connection with the Services, we may collect personal data that identifies you as an individual if you submit such data to us or use the Services. The kinds of personal data which we may collect from you directly, either on paper, through phone or email, or at the time you register for the Service, use the Service or update your personal information therein, include: (i) your name; (ii) your email address, phone number and other contact information; (iii) address information; (iv) company; (v) company´s Business ID; (vi) billing information, (vii) proposals created and data stored therein including any attachments such as service descriptions, blueprints and price lists;and  (viii) comments and feedback related to  business proposals and contracts
      </p>
      <p>
      Data may also be collected from other service providers and information collected on the basis of analytics found on the website. The kinds of data which we may collect include: (i) credit information (ii) company´s contact information and information of decisionmakers (iii) user activity of the provided Service, for example  
      </p>
      <p>
      which pages are browsed and for how long, the number of logins or other similar user data and (iv) device and connection information such as IP address, device ID etc. Personal data (especially browsing and user data) may be used to develop the Service. In addition, the data may be used to create profiles of the users of the Service and target advertising to them. The profiling is based on the site use analytics described above as well as possible other browsing data received through a third-party analytics software. The advertising is targeted based on the profile created based on the profiling. Your separate consent is required to execute this kind of electronic advertising, and you may revoke your consent at any later time.
      </p>
      <h6>Data Protection and Retention of Data</h6>
      <p>
      Your personal data may be stored and processed in servers and data centers which are located outside the European Union or European Economic Area. In these cases we only use processors who have agreed to the use of data protection model clauses drafted by the European Commission to ensure the same level of privacy as offered inside the EU, or are part of the EU-US Privacy Shield program, or both.
      </p>
      <p>
      All personal data is stored and protected according to generally accepted industry standards but data transmission via internet is unfortunately never fully secure so we cannot ensure the integrity of the transferred data to be 100% secure. We protect our Service with technical and organizational measures against unauthorized use or destruction of data by sending data only in encrypted forms. We store your data only for the time it is necessary for providing the Service. After the data processing for this purpose is finished we will delete your data unless regulations demand us to store data for a longer period or we can point out another legitimate interest for the continued processing of some parts of your personal data (for example for marketing). We will apply the data minimization principle to all data processing for all processing purposes.
      </p>
      <h6>Disclosing Personal Data</h6>
      <p>
      Apart from following situations we shall not disclose your personal data to a third party:
      </p>
      <dl>
      <dt>a)</dt>
      <dd>
      We may disclose information to a third party if you have given a consent to do that or in the event of some legislation or other authorities´ regulation or order legitimizes or obligates us to do so. In the events like this regulation related to law enforcement, general safety or monitoring intellectual property rights might be in question; or
      </dd>
      <dt>b)</dt>
      <dd>
      We may disclose your data to our sub-contractors, in which cases we shall be responsible for their conduct as for our own.
      </dd>
      </dl>
      <h6>Your Rights</h6>
      <p>
      You have the right to obtain information about processing your personal data. At any time, you can review and verify or request to remedy, update or modify personal data or to demand deleting the information from our register. Note that some information is necessary for purposes mentioned in this document and for example deleting the information may lead to service becoming unusable. You have the right to prohibit processing personal data for direct marketing and profiling purposes separately. In addition, you have the right to obtain your collected personal data in a structured, standardized and machine-readable form and transfer such data further to other systems. In the event that you are not pleased with the way we have been processing your personal data, you may at any time contact the national supervisory authority (in Finland Data Protection ombudsman, whose contact information is found at the address http://www.tietosuoja.fi), to settle the matter.
      </p>
      <h6>Further Information</h6>
      <p>
      We reserve the right to amend the terms of this record at any time in the future. The updated version of the record is available at all times through the proposals you receive or the Service itself. Visit the Service regularly and verify the content of our updated record. Your continued use of the Service after the posting of a new version is deemed as your acceptance of the modified terms.
      </p>
      <h6>Contact Information</h6>
      <p>
      If you have any questions related to data protection or you would like to withdraw your consent, we will be glad to help you.
      </p>
      Our contact information:
      <br/>
      Duuers Oy 
      <br/>
      Innopoli 3, D-talo
      <br/>
      Vaisalantie 8
      <br/>
      FI-02130 Espoo
      <br/>
      Finland
      <p>
      The responsible person for personal data processing in accordance with this record is:
      <br/>
      Erkka Hänninen
      <br/>
      support@duuers.com
      </p>
    </TextPage>
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)

export default Privacy
